import React, { useContext, useState } from 'react';
import './styles.scss';
import Dropdown from '../../components/Dropdown';
import { useTranslation } from 'react-i18next';
import AppStateContext from '../../AppStateProvider/context';
import { ScreenEnum } from '../../enums';
import ResetOverlay from '../../components/ResetOverlay';
import { WelcomeProps } from './interfaces';
import RadioButtons from '../../components/RadioButtons';
import { deviceTypeList } from '../../utils/deviceTypeList';

export default function Welcome(props: WelcomeProps): JSX.Element {
	const { state, dispatch } = useContext(AppStateContext);
	const { t, i18n } = useTranslation();
	const [resetOverlay, setResetOverlay] = useState<boolean>(false);
	const [nextScreen, setNextScreen] = useState<ScreenEnum>(
		ScreenEnum.validation
	);

	return (
		<>
			<div className={`top${state.setup ? ' less-padding' : ''}`}>
				<div className='welcome'>
					<h1>{t('WelcomeHeadline')}</h1>
					<p className='p1'>
						{t(
							state.setup
								? 'WelcomeExistingSetupText'
								: 'WelcomeText'
						)}
					</p>
					{props.version && (
						<>
							<h4>{t('WelcomeVersion')}</h4>
							<p className='p2'>{props.version}</p>
						</>
					)}
					{!state.setup ? (
						<label>
							<h4>{t('WelcomeLabelLanguage')}</h4>
							<Dropdown
								list={state.dataJson.languages}
								set={(value: string) => {
									i18n.changeLanguage(value);
									dispatch({
										type: 'SET_LANGUAGE',
										value: value,
									});
								}}
								defaultValue={
									(
										state.dataJson.languages as Array<any>
									).find(
										lang => lang.value === state.language
									).label || state.dataJson.languages[0].label
								}
							/>
						</label>
					) : (
						<RadioButtons
							options={[
								{
									selected:
										nextScreen === ScreenEnum.validation,
									text: t('WelcomeChangePricesheet'),
									value: ScreenEnum.validation,
								},
								{
									selected:
										nextScreen === ScreenEnum.password,
									text: t('WelcomeSetNewPassword'),
									value: ScreenEnum.password,
									displayNone: state.apk,
								},
								{
									selected: nextScreen === ScreenEnum.exit,
									text: t('WelcomeTurnOffApp'),
									value: ScreenEnum.exit,
									displayNone: !state.apk,
								},
								{
									selected: nextScreen === ScreenEnum.welcome,
									text: t('WelcomeResetConfig'),
									value: ScreenEnum.welcome,
								},
							]}
							onClick={(value: ScreenEnum) =>
								setNextScreen(value)
							}
						/>
					)}
				</div>
			</div>
			<div className='buttons'>
				{state.setup === null && (
					<button
						className='white'
						onClick={() =>
							props.setScreen(
								deviceTypeList.length > 1
									? ScreenEnum.hardwareCheck
									: ScreenEnum.softwareCheck
							)
						}
					>
						{t('ButtonStart')}
					</button>
				)}
				{state.setup !== null && (
					<>
						<button
							className='white'
							onClick={() => {
								if (nextScreen === ScreenEnum.welcome) {
									setResetOverlay(true);
								} else {
									props.setScreen(nextScreen);
								}
							}}
						>
							{t('Confirm')}
						</button>
						<button
							className='cancel'
							onClick={() => props.setScreen(ScreenEnum.final)}
						>
							{t('Cancel')}
						</button>
					</>
				)}
			</div>
			{resetOverlay && (
				<ResetOverlay
					setResetOverlay={setResetOverlay}
					setScreen={props.setScreen}
				/>
			)}
		</>
	);
}
