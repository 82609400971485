import React, {useContext, useEffect, useState} from 'react';
import WarningOverlay from '../WarningOverlay';
import {ScreenEnum} from '../../enums';
import AppStateContext from '../../AppStateProvider/context';
import {complete} from '../../utils/complete';
import {redirect} from '../../utils/redirect';
import i18next from 'i18next';
import {fallbackTranslations} from '../../fallbackTranslations';

export default function WarningStartingCheck(props: {
	timeOk: number | boolean | null;
	setTimeOk: React.Dispatch<React.SetStateAction<number | boolean | null>>;
	offline: boolean;
	setOffline: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const { state, dispatch } = useContext(AppStateContext);
	const params = new URLSearchParams(window.location.search);
	const apk = params.get('apk') === 'true';
	const [seconds, setSeconds] = useState<number | null>(null);
	const [error, setError] = useState<boolean>(false);
	const redirectPossible =
		params.get('redirect') !== 'false' && complete(state) && !error;

	var text = 'WarningStartingCheckDefaultWarning';
	if (props.offline && !error) {
		if (redirectPossible) {
			text = 'WarningStartingCheckOfflineRedirectPossible';
		} else {
			text = 'WarningStartingCheckOffline';
		}
	} else if (props.timeOk !== false && !error) {
		text = 'WarningStartingCheckTimeOff';
	}

	const exit = () => {
		props.setTimeOk(true);
		props.setOffline(false);
		if (apk)
			dispatch({
				type: 'SET_SCREEN',
				value: ScreenEnum.exitNoCancel,
			});
	};

	useEffect(() => {
		if (redirectPossible && seconds === null) setSeconds(15);
		if (seconds && seconds > 0) {
			const intervalId = setInterval(() => {
				setSeconds(seconds => (seconds || 1) - 1);
			}, 1000);

			return () => clearInterval(intervalId);
		} else if (seconds === 0) {
			setTimeout(() => setError(true), 3000);
			redirect(state.setup.brand);
		}
	}, [seconds]);

	return (
		<WarningOverlay
			headline={i18next.t('Warning')}
			texts={[
				i18next.t(text) !== text
					? i18next.t(text)
					: fallbackTranslations[text] || text,
				seconds && !error ? seconds + 's' : undefined,
			]}
			btnFunction={() => {
				if (
					props.offline &&
					params.get('redirect') !== 'false' &&
					complete(state) &&
					!error
				) {
					setTimeout(() => setError(true), 3000);
					redirect(state.brand);
				} else {
					exit();
				}
			}}
			okBtn={apk && true}
		/>
	);
}
